import React, { Component } from "react";

class Services extends Component {

  render() {
    return (    
        
    <section class="text-gray-600 body-font">    
        <div class="container px-5 py-24 mx-auto">
        <div>
            <div class="px-5 lg:px-16 xl:px-20">
                <h1 class="text-4xl font-semibold font-sans mb-6">
                    <span class="text-white">Dienstleistungen</span>
                </h1>
            </div>
        </div>
        <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">Terrassenbau</h1>
                    <p class="leading-relaxed text-gray-400 mb-3">
                    Neben dem Bau von Holz, Natur, Pflaster und WBC Terrassen haben wir und auf Keramikterrassen spezialisiert.
                    </p>
                </div>
            </div>
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">Zaunbau</h1>
                    <p class="leading-relaxed text-gray-400 mb-3">
                        Wir installieren Zäune aller Art aus Holz, WBC und Doppelstabmatten.
                        </p>
                </div>
            </div>
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">
                        Baumpflege und -fällung
                    </h1>
                    <p class="leading-relaxed text-gray-400  mb-3">
                    Wir fällen Problembaüme, Geschult im Baumklettern Seilklettertechnik und bieten Baumpflege aller Art an.
                    </p>
                </div>
            </div>
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">Erdarbeiten</h1>
                    <p class="leading-relaxed text-gray-400  mb-3">
                        Wir entwässern, machen Rohr- und Leitungsbau, Fundament- und Planierungsarbeiten.
                    </p>
                </div>
            </div>
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">Vegetationsarbeiten</h1>
                    <p class="leading-relaxed text-gray-400  mb-3">Wir bieten Großbaumverpflanzung und Pflanzarbeiten an und legen Grünflächen und Rasen an.</p>
                </div>
            </div>
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">Gartenbau</h1>
                    <p class="leading-relaxed text-gray-400 mb-3">Wir gestalten Gärten und Beete, sowie Hochbeete um und neu. Wir legen Mähkanten an und fassen Beete ein.
                    Außerdem bauen wir Trockenmauern und Ruinenmauern und legen Teiche an.</p>
                </div>
            </div>
            <div class="p-4 lg:w-1/3">
                <div class="h-full bg-gray-700 bg-opacity-25 px-8 pt-8 pb-8 rounded-lg overflow-hidden text-center relative">
                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-500 mb-3">Pflasterarbeiten</h1>
                    <p class="leading-relaxed text-gray-400 mb-3">Wir legen Auffahrten und Wege an und pflastern mit Klinker, 
                    Kleinstein- und Naturpflaster, sowie Betongestaltungspflaster.</p>
                </div>
            </div>          
        </div>
        </div>
    </section>
    );
  }
}

export default Services;