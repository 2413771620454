import React, { Component } from "react";

class LandingPage extends Component {

  render() {
    return (    
        <section class="">
            <div id="landingPage" 
            class="bg-[url('https://i.imgur.com/jAXaawT.jpg')] h-screen bg-cover bg-center 
            flex justify-items-center items-center">
            <div class="px-10 lg:px-32 xl:px-40">
                <h1 class="text-6xl font-semibold font-sans mb-6">
                <span class="text-white">Garden Stories</span>
                </h1>
                <p class="text-gray-500 text-lg max-w-md">Die Garten- und Landschaftsbaufirma aus dem Ammerland</p>
            </div>
            </div>
        </section>    
    );
  }
}

export default LandingPage;