import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Footer extends Component {

  render() {
    return (    
        <div className="footer">
        <footer>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-800 bg-gray-800 text-center">
            <div className="grid grid-cols-3 gap-4 w-50 border-t border-gray-800 bg-gray-800 text-center">
            <NavLink className="nav-link text-gray-600" to="/">
                Start
            </NavLink>
            <NavLink className="nav-link text-gray-600" to="/impressum">
                Impressum  
            </NavLink>
            <NavLink className="nav-link text-gray-600" to="/datenschutz">
                Datenschutz 
            </NavLink>
            
            </div>
            <br />
            <a className="text-gray-600">+49 (0)172 910 9152</a>
            <br />
            <a className="text-gray-600">info@garden-stories-galabau.de</a>
            <br />            
            <p className="leading-normal my-5">
                Mirko Ripken
                <br />
                Margarete-Steiff-Straße 4
                <br />
                26160 Bad Zwischenahn
            </p>
            <span className="inline-flex">
                <button 
                onClick={(e) => {
                    e.preventDefault();
                    window.location.href='http://instagram.com/gardenstories_gartenbau?utm_medium=copy_link';
                    }}
                className="ml-4 text-gray-500">
                <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    >
                    <rect
                    width="20"
                    height="20"
                    x="2"
                    y="2"
                    rx="5"
                    ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
                </button>
            </span>
            </div>
        </footer>
        </div> 
    );
  }
}

export default Footer;