import React, { forwardRef } from "react"
import Footer from "./components/Footer";
import {Link, scrollSpy, Events, Element, animateScroll} from 'react-scroll';


const Impressum = forwardRef(({ caption, scroll }, ref) => (
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
    }}
    className="scroll"
    id="containerElement"
    >     
      
      <div className=" flex">
      <div class="px-10 lg:px-32 xl:px-40">
          <h1 class="text-6xl font-medium font-sans mb-6">
          <span class="text-white">Impressum</span>
          </h1>
          <p class="text-gray-600 text-medium max-w-md">
            Angaben gemäß § 5 TMG und Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          </p>
          <br/>
          <p class="text-gray-600 text-s max-w-md">
            Mirko Ripken
          </p>
          <p class="text-gray-600 text-s max-w-md">
            Margarete-Steiff-Straße 4
          </p>
          <p class="text-gray-600 text-s max-w-md">
            26160 Bad Zwischenahn
          </p>
          <br/>
          <p class="text-gray-600 text-s max-w-md">
            Vertreten durch Mirko Ripken
          </p>

          <br/>
          <h1 class="text-xl font-medium font-sans mb-6">
          <span class="text-white">Umsatzsteuer-ID:</span>
          </h1>
          <br/>
          <p class="text-gray-600 text-s max-w-md">
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: 
          </p>
          <br/>
          <p class="text-gray-600 text-s max-w-md">
          DE34 1657 341
          </p>
          
          <br/>
          <h1 class="text-xl font-medium font-sans mb-6">
          <span class="text-white">Steuer-ID:</span>
          </h1>
          <br/>
          <p class="text-gray-600 text-s max-w-md">
          69/136/03464
          </p>
          
      </div>
    </div>   
    <Footer/>   
   
  </div>
))

export default Impressum
