import React, { forwardRef } from "react"
import AboutUs from "./components/AboutUs";
import LandingPage from "./components/LandingPage";
import Services from "./components/Services";
import References from "./components/References";
import Projects from "./components/Projects";
import ContactUs from "./components/ContactUs";
import JobOffers from "./components/JobOffers";
import Footer from "./components/Footer";
import Space from "./components/Space";
import Header from "./components/Header";
import {Link, scrollSpy, Events, Element, animateScroll} from 'react-scroll';


const Overlay = forwardRef(({ caption, scroll }, ref) => (
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
    }}
    className="scroll"
    id="containerElement"
    >     
      
    <Header/>
    <Element name="landingPage">
      <LandingPage/>
    </Element>     
    <Element name="aboutUs">
      <AboutUs/>
    </Element>
    <Space/>
    <Element name="services">
      <Services/>
    </Element> 
    <References/>
    <Space/>    
    <Element name="projects">
      <Projects/>
    </Element> 
    <Space/>
    <Element name="contact">
      <ContactUs/>
    </Element>
    <Element name="jobOffers">
      <JobOffers/>
    </Element> 
    <Footer/> 
   
  </div>
))

export default Overlay
