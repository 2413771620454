import React, { Component } from "react";
import { Link } from 'react-scroll';

class MenuLinks extends Component {

  toggle = () => {
    document.getElementById("nav-content").classList.toggle("hidden");
  };

  render() {
    return ( 
    <li className="mr-3">
        <Link 
        onClick={this.toggle}
        activeClass="active" 
        to={this.props.refTo}
        spy={true} smooth={true} duration={250} 
        containerId="containerElement">
            <a 
            className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" 
            href="#">
            {this.props.title}
            </a>
        </Link>
    </li>
    
    )}
}

export default MenuLinks 

