import React, { Component } from "react";
import Images from "./Images";

class Projects extends Component {

  render() {
    return (    
        <section class="text-gray-600 body-font bg-gray-700">
          <div class="h-sceen container px-5 py-24 mx-auto">      
          <div class="px-5 lg:px-16 xl:px-20">
            <h1 class="text-4xl font-semibold font-sans mb-6">
              <span class="text-white">Unsere Arbeit</span>
            </h1>
          </div>
            <div class="flex flex-wrap -m-4">
              <Images 
                imageLink="img/IMG-20210721-WA0017.jpg" 
                //header="MAUERN" 
                topic="Ruinenmauer"
                text="Aus alten Klinkersteinen wurde eine Ruinenmauer aufgebaut mit alten
                gusseiserenen Fenstern. Davor wurde eine Terrasse aus alten Klinkern, Findlingen und Sandstein
                gebaut."/>
              <Images 
                imageLink="img/IMG-20210721-WA0027copy.png" 
                //header="PFLASTERN" 
                topic="Einfahrt"
                text="Da die Auffahrt im Moorgebiet gebaut wurde, mussten wir zwei Meter tief den Boden
                austauschen. Jetzt hält die Auffahrt für die Ewigkeit und wird nicht Absacken auch wenn
                schweres Gefährt, mal zu Besuch ist."/>
              <Images 
                imageLink="img/IMG-20210721-WA0070.jpg" 
                //header="FUNDAMENT" 
                topic="Holzterrasse"
                text="Die Holzterrasse wurde 2 Meter über den See hinaus gebaut. Durch die großen
                Sandsteine wurde eine Abstützung im Wasser realisiert. Unter der Terrasse wurde eine stabile
                Stahlkonstruktion aufgebaut."/>
              <Images 
                imageLink="img/IMG-20210721-WA0031.jpg" 
                //header="ZIMMERN" 
                topic="WPC Terrasse"
                text="WPC vereint die Vorteile 
                von Holz und Kunststoff - Sie erhalten eine 
                witterungsbeständige und langlebige Terrasse in natürlicher Holzoptik."/>
              <Images 
                imageLink="img/IMG-20210721-WA0032.jpg" 
                //header="PFLASTERN" 
                topic="Pflasterterrasse und Beete"
                text="Hier wurde eine alte Terrasse abgebaut und eine neue
                gepflastert. Dazu wurden die Beete rund um die Terrasse neu angelegt."/>
              <Images 
                imageLink="img/IMG-20210721-WA0040.jpg" 
                //header="ZIMMERN" 
                topic="Keramikterrasse:"
                text="Bau einer wunderschönen Keramikterrasse mit Mähkante und Beeteinfassung.
                Als kleines Highlight wurde noch eine Feuerstelle eingefasst."/>
              <Images 
                imageLink="img/IMG-20210721-WA0041.jpg" 
                //header="PFLASTERN" 
                topic="Eingangspodest"
                text="Schönes Eingangspodest aus Klinker und in Granit eingefasst. Die Klinker wurden einzeln angeschrägt,
                damit das Ergebnis schön und symmetrisch wird."/>
              <Images 
                imageLink="img/IMG-20210721-WA0047.jpg" 
                //header="ZIMMERN" 
                topic="Sitzbank"
                text="Eine Ecke zum Wohlfühlen. Wir haben eine freischwebende Bank gebaut. In ein paar
                Jahren wird der Baum der aus der Bank wächst den nötigen Schatten spenden."/>
              <Images 
                imageLink="img/IMG-20210721-WA0062.jpg" 
                //header="BAGGER FAHREN" 
                topic="Pavillion"
                text="Wir können Bagger sehr genau steuern und so auch schwierige Probleme schnell und zuverlässig
                lösen. Wir legen darauf Wert sauber und präzise zu arbeiten."/>
              <Images 
                imageLink="img/IMG-20210721-WA0006.jpg" 
                //header="PFLASTERN" 
                topic="Beete anlegen"
                text="Um den idyllischen Pavillion wurden Beete angelegt, ein Pflanzbogen als Eingang gebaut und
                die Innenflächen kreisrund mit einem schönen Muster gepflastert."/>
                <Images 
                  imageLink="img/IMG-20211118-WA0007.jpg" 
                  //header="PFLASTERN" 
                  topic="Terrasse aus Polygonalplatten"
                  text="Polygonalplatten sind robust und ein perfekter Bodenbelag mit 
                  natürlichem Charme. Durch ihre unregelmäßigen 
                  Formen sind sie bei uns eine gern gesehene Herausforderung."/>
                <Images 
                  imageLink="img/IMG-20210721-WA0012.jpg" 
                  //header="PFLASTERN" 
                  topic="Seilklettertechnik"
                  text="Als geprüfter Baumkletterer können wir das Klettersystem im Baum installieren, 
                  problemlos jede Stelle im Baum erreichen und den Baum pflegen, beschneiden oder fällen."/>
                <Images 
                  imageLink="img/20220201_112943.jpg" 
                  //header="PFLASTERN" 
                  topic="Großbaumverpflanzung mit Spezialgerät"
                  text="Wir können alle Geräte, die im Garten- und Landschaftsbau nötig sind, fahren.
                  So garantieren wir schnelles und effektives Arbeiten. Hier
                  verpflanzen gerade mit einem Spezialgerät einen großen Baum. "/>
            </div>
          </div>
        </section>
    
    );
  }
}

export default Projects;