import React, { Component } from "react";

class Images extends Component {
  render() {
    return ( 
    <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
            <img alt="gallery" class="absolute inset-0 object-cover object-center w-full h-full rounded-lg"
            src={this.props.imageLink}/>
            <div class="px-8 py-10 relative object-cover w-full bg-white bg-opacity-0 hover:bg-opacity-50 opacity-0 hover:opacity-100 rounded-lg">
            <h2 class="tracking-widest text-sm title-font font-bold text-gray-700 mb-1">{this.props.header}</h2>
            <h1 class="title-font text-lg font-bold text-gray-900 mb-3">{this.props.topic}</h1>
            <p class="leading-relaxed font-bold text-gray-800">{this.props.text}</p>
            </div>
        </div>
    </div>
    
    )}
}

export default Images 