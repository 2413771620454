import React, { Component } from "react";
import { Link } from 'react-scroll';
import MenuLinks from "./MenuLinks";
import { NavLink } from "react-router-dom";


class Header extends Component {

  toggle = () => {
    document.getElementById("nav-content").classList.toggle("hidden");
  };

  render() {
    return (        
    <nav className="flex items-center justify-between flex-wrap  p-6 fixed w-full z-10 top-0">
      <div className="flex items-center flex-shrink-0 text-white mr-6"></div>

      <div className="block lg:hidden">
        <button 
          onClick={this.toggle}
          id="nav-toggle" 
          className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white">
            <svg 
              className="fill-current h-3 w-3" 
              viewBox="0 0 20 20" 
              xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
        </button>
      </div>

      
      <div 
      className="w-full bg-gray-800 flex-grow lg:flex lg:items-center rounded-lg lg:w-auto hidden lg:block pt-6 lg:pt-0" 
        id="nav-content">       
        <ul className="list-reset lg:flex justify-end flex-1 items-center">
          <MenuLinks title='Home' refTo='landingPage'/>    
          <MenuLinks title='Über uns' refTo='aboutUs'/>
          <MenuLinks title='Dienstleistungen' refTo='services'/>
          <MenuLinks title='Unsere Arbeit' refTo='projects'/>
          <MenuLinks title='Kontakt' refTo='contact'/>
          <MenuLinks title='Jobs' refTo='jobOffers'/>
        </ul>        
      </div>
      
    </nav>   
    );
  }
}

export default Header;

