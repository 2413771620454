import React, { Suspense, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import Model from "./Model"
import Overlay from "./Overlay"
import './index.css';

//https://docs.aws.amazon.com/AmazonS3/latest/userguide/website-hosting-custom-domain-walkthrough.html#root-domain-walkthrough-create-buckets

export default function App() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)

  return (
    <>
      <Canvas 
        gl={{
          powerPreference: "high-performance",
          antialias: true,
          stencil: true,
          depth: true
        }}        
        shadows
        style={{background: 'black'}} 
        camera = {{position: [ 3, 3, 3 ]}}>
        <color attach="background" args={["#062e15"]} />
        <ambientLight intensity={0.5} />
        <Suspense fallback={null}>
          <Model scroll={scroll} />
        </Suspense>
      </Canvas>      
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
    </>
  )
}
