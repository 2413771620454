/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useGLTF, useAnimations, PerspectiveCamera, Box } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"

const color = new THREE.Color()

export default function Model({ scroll, ...props }) {
  const t = useRef(0)
  const group = useRef()
  const { nodes, materials, animations } = useGLTF("/BlumenUpdated.glb")
  const { actions, mixer } = useAnimations(animations, group)  
  const [hovered, set] = useState()
  const extras = { receiveShadow: true, castShadow: true, "material-envMapIntensity": 0.5 }
  
  nodes.flower01.children[0].material.metalness = 0.5;
  nodes.flower01.children[1].material.metalness = 0.1;
  nodes.flower01.children[2].material.metalness = 0.5;
  nodes.flower01.children[3].material.metalness = 0.2;
  nodes.flower01.children[4].material.metalness = 0.5;
  nodes.flower01.children[5].material.metalness = 0.7;

  nodes.flower02.children[0].material.metalness = 0.2;
  nodes.flower02.children[1].material.metalness = 0.2;
  nodes.flower02.children[2].material.metalness = 0.2;
  nodes.flower02.children[3].material.metalness = 0.2;
  nodes.flower02.children[4].material.metalness = 0.2;

  nodes.flower03.children[0].material.metalness = 0.2;
  nodes.flower03.children[1].material.metalness = 0.2;
  nodes.flower03.children[2].material.metalness = 0.2;

  nodes.flower04.children[0].material.metalness = 0.2;
  nodes.flower04.children[1].material.metalness = 0.2;
  nodes.flower04.children[2].material.metalness = 0.2;
  nodes.flower04.children[3].material.metalness = 0.2;
  nodes.flower04.children[4].material.metalness = 0.2;
  nodes.flower04.children[5].material.metalness = 0.2;
  nodes.flower04.children[6].material.metalness = 0.2;
  nodes.flower04.children[7].material.metalness = 0.2;

  nodes.flower05.children[0].material.metalness = 0.2;
  nodes.flower05.children[1].material.metalness = 0.2;
  nodes.flower05.children[2].material.metalness = 0.2;

  useEffect(() => void (actions["CameraAction.005"].play().paused = true), [])

  useEffect(() => {
    if (hovered) group.current.getObjectByName(hovered).material.color.set("white")
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])

  useFrame((state) => {
    actions["CameraAction.005"].time = 
      THREE.MathUtils.lerp(actions["CameraAction.005"].time, 
      actions["CameraAction.005"].getClip().duration * scroll.current, 
      0.05)    
    group.current.children[0].children.forEach((child, index) => {
      child.material.color.lerp(color.set(hovered === child.name ? "#c4c4c4" : "#707070").convertSRGBToLinear(), hovered ? 0.1 : 0.05)
      const et = state.clock.elapsedTime
      child.position.y = Math.sin((et + 2000) / 2) * 1
      child.rotation.x = Math.sin((et + 2000) / 3) / 10
      child.rotation.y = Math.cos((et + 2000) / 2) / 10
      child.rotation.z = Math.sin((et + 2000) / 3) / 10
    })
  })

  return (
    <group ref={group} {...props} dispose={null}>       
      <group
        onPointerOver={(e) => (e.stopPropagation(), set(e.object.name))}
        onPointerOut={(e) => (e.stopPropagation(), set(null))}
        position={[0, 0, -1]}
        scale={[1, 1, 1]}>
        
        <mesh name="flower01" geometry={nodes.flower01.children[0].geometry} material={nodes.flower01.children[0].material} {...extras}/>
        <mesh name="flower01" geometry={nodes.flower01.children[1].geometry} material={nodes.flower01.children[1].material} {...extras}/>
        <mesh name="flower01" geometry={nodes.flower01.children[2].geometry} material={nodes.flower01.children[2].material} {...extras}/>
        <mesh name="flower01" geometry={nodes.flower01.children[3].geometry} material={nodes.flower01.children[3].material} {...extras}/>
        <mesh name="flower01" geometry={nodes.flower01.children[4].geometry} material={nodes.flower01.children[4].material} {...extras}/>
        <mesh name="flower01" geometry={nodes.flower01.children[5].geometry} material={nodes.flower01.children[5].material} {...extras}/>
      
        <mesh name="flower02" geometry={nodes.flower02.children[0].geometry} material={nodes.flower02.children[0].material} {...extras}/>
        <mesh name="flower02" geometry={nodes.flower02.children[1].geometry} material={nodes.flower02.children[1].material} {...extras}/>
        <mesh name="flower02" geometry={nodes.flower02.children[2].geometry} material={nodes.flower02.children[2].material} {...extras}/>
        <mesh name="flower02" geometry={nodes.flower02.children[3].geometry} material={nodes.flower02.children[3].material} {...extras}/>
        <mesh name="flower02" geometry={nodes.flower02.children[4].geometry} material={nodes.flower02.children[4].material} {...extras}/>
      
        <mesh name="flower03" geometry={nodes.flower03.children[0].geometry} material={nodes.flower03.children[0].material} {...extras}/>
        <mesh name="flower03" geometry={nodes.flower03.children[1].geometry} material={nodes.flower03.children[1].material} {...extras}/>
        <mesh name="flower03" geometry={nodes.flower03.children[2].geometry} material={nodes.flower03.children[2].material} {...extras}/>
        <mesh name="flower03" geometry={nodes.flower03.children[3].geometry} material={nodes.flower03.children[3].material} {...extras}/>
        
        <mesh name="flower04" geometry={nodes.flower04.children[0].geometry} material={nodes.flower04.children[0].material} {...extras}/>
        <mesh name="flower04" geometry={nodes.flower04.children[1].geometry} material={nodes.flower04.children[1].material} {...extras}/>
        <mesh name="flower04" geometry={nodes.flower04.children[2].geometry} material={nodes.flower04.children[2].material} {...extras}/>
        <mesh name="flower04" geometry={nodes.flower04.children[3].geometry} material={nodes.flower04.children[3].material} {...extras}/>
        <mesh name="flower04" geometry={nodes.flower04.children[4].geometry} material={nodes.flower04.children[4].material} {...extras}/>
        <mesh name="flower04" geometry={nodes.flower04.children[6].geometry} material={nodes.flower04.children[6].material} {...extras}/>
        <mesh name="flower04" geometry={nodes.flower04.children[7].geometry} material={nodes.flower04.children[7].material} {...extras}/>
      
        <mesh name="flower05" geometry={nodes.flower05.children[0].geometry} material={nodes.flower05.children[0].material} {...extras}/>
        <mesh name="flower05" geometry={nodes.flower05.children[1].geometry} material={nodes.flower05.children[1].material} {...extras}/>
        <mesh name="flower05" geometry={nodes.flower05.children[2].geometry} material={nodes.flower05.children[2].material} {...extras}/>
        <mesh name="flower05" geometry={nodes.flower05.children[3].geometry} material={nodes.flower05.children[3].material} {...extras}/>
        <mesh name="flower05" geometry={nodes.flower05.children[4].geometry} material={nodes.flower05.children[4].material} {...extras}/>
        <mesh name="flower05" geometry={nodes.flower05.children[5].geometry} material={nodes.flower05.children[5].material} {...extras}/>
        <mesh name="flower05" geometry={nodes.flower05.children[6].geometry} material={nodes.flower05.children[6].material} {...extras}/>
        
        <mesh name="flower06" geometry={nodes.flower06.children[0].geometry} material={nodes.flower06.children[0].material} {...extras}/>
        <mesh name="flower06" geometry={nodes.flower06.children[1].geometry} material={nodes.flower06.children[1].material} {...extras}/>
        <mesh name="flower06" geometry={nodes.flower06.children[2].geometry} material={nodes.flower06.children[2].material} {...extras}/>
        <mesh name="flower06" geometry={nodes.flower06.children[3].geometry} material={nodes.flower06.children[3].material} {...extras}/>
        
      </group> 
      <group name="Camera" position={[-1.78, 2.04, 23.58]} rotation={[1.62, 0.01, 0.11]}>
        <PerspectiveCamera makeDefault far={100} near={0.1} fov={28} rotation={[-Math.PI / 2, 0, 0]}>
          <directionalLight
            castShadow
            position={[10, 20, 15]}
            shadow-camera-right={12}
            shadow-camera-top={12}
            shadow-camera-left={-12}
            shadow-camera-bottom={-12}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={1}
            shadow-bias={-0.0001}
          />
          <ambientLight
            intensity={0.5}
          />
        </PerspectiveCamera>
      </group>            
    </group>
  )
}

useGLTF.preload("/model.glb")
