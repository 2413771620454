import React, { Component } from "react";
import {Element} from 'react-scroll';

class AboutUs extends Component {

  render() {
    return (    
        <section class="flex flex-col text-center w-full mb-12">
          <div  id="about" class="max-w-2xl mx-auto lg:w-1/3 px-5">  
            <div class="h-full items-center bg-gray-700 bg-opacity-25 px-8 pt-8 
            pb-8 rounded-lg overflow-hidden text-center relative">
              {/*<h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">DIENSTLEISTUNG</h2>*/}
              <img class="mb-3 w-50 h-50 rounded-full shadow-lg" src="img/mirko2.jpg" alt="Mirko image"/>
              <h1 class="title-font sm:text-2xl text-xl font-bold text-gray-500 mb-3">Mirko Ripken</h1>
              <p class="leading-relaxed text-gray-300 mb-3">Meister für Garten- und Landschaftsbau</p>
              <p class="leading-relaxed h-full text-gray-500 mb-3">
              Hallo, ich bin Mirko Ripken, Meister im Garten und Landschaftsbau.
              Schon vor meiner Ausbildung war ich immer mit vollem Eifer dabei, wenn es 
              um die Gestaltung im Garten ging.
              Nach meiner Ausbildung im Garten- und Landschaftsbau war es für mich klar 
              welchen Weg ich einschlagen werde. Zuerst habe ich meinen Meister gemacht 
              und bin dann für ein Jahr nach Süddeutschland gezogen um dort in einem 
              renommierten Garten- und Landschaftsbau-Unternehmen zu arbeiten. Von dort konnte 
              ich sehr viel neues Wissen mit in die Heimat nehmen.
              Mit meinem Können und Wissen gab es für mich nur noch die Option der 
              Selbständigkeit. Ich möchte die Projekte, die mir anvertraut werden, nach 
              meinen Ansprüchen an mich umsetzen und das immer
              zur Zufriedenheit meines Kunden.
              Ab dem ersten Tag meiner Selbständigkeit sollte sich diese Entscheidung als die Richtige
              herausstellen. Die Aufträge wurden immer mehr und mein Team um mich wuchs an und der
              Kundenkreis ebenso.
              Mit einem jungen und dynamischen Team freuen wir uns auf spannende Projekte und neue
              Aufgaben.
              </p>
            </div>
          </div>
        </section>  
    );
  }
}

export default AboutUs;