import ReactDOM from "react-dom"
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles.css"
import App from "./App"
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";

ReactDOM.render(
    <Router>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />            
        </Routes>        
    </Router>,     
    document.getElementById("root")
)
