import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ro67o1e', 'template_mv7n96m', form.current, 'user_AtPhwhYS2kg8hkTvGl9KZ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };

  return (
    <section>
    <form ref={form} onSubmit={sendEmail}>
      <div className="px-5 py-24 realtive mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <br/>
          <h1 className="sm:text-5xl text-2xl font-medium title-font mb-4 text-white">
            Kontaktiere mich!
          </h1>
          <p className="lg:w-2/3 mx-auto text-gray-500 leading-relaxed text-base">
            Ob du Fragen zu meinen Leistungen, Preisen oder meiner Auftragslage hast, ich freue mich über dein Interesse! Vielleicht möchtest du mir auch nur Feedback zu meiner Arbeit geben.
          </p>
        </div>
        
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label for="name" className="leading-7 text-sm text-gray-400">
                  Name
                </label>
                <input
                  name="user_name"
                  type="text"
                  id="name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border 
                  border-gray-300 focus:border-gray-500 text-base outline-none 
                  text-gray-900 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label
                  for="email"
                  className="leading-7 text-sm text-gray-400 bg-opacity-50"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="user_email" 
                  className="w-full bg-gray-100 bg-opacity-50 rounded border 
                  border-gray-300 focus:border-gray-500 text-base outline-none 
                  text-gray-900 py-1 px-3 leading-8 transition-colors 
                  duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="message"
                  className="leading-7 text-sm text-gray-400"
                >
                  Nachricht
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border 
                  border-gray-300 focus:border-gray-500 h-32 text-base outline-none 
                  text-gray-900 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
            </div>
            <div className="p-2 w-full">
              <button 
                type="submit" value="Send"
                className="flex mx-auto text-white bg-gray-500 border-0 py-2 px-8 
                focus:outline-none hover:bg-gray-600 rounded text-lg">
                Senden
              </button>
            </div>
          </div>
        </div>        
      </div>
    </form>
    </section>
  );
};

export default ContactUs

{/**
    <form ref={form} onSubmit={sendEmail}>
      <input type="text" name="user_name" />
      <input type="email" name="user_email" />
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form>




  ////////
 <section className="text-gray-700 body-font relative">
  <form ref={form} onSubmit={sendEmail}>
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-12">
    <br/>
      <h1 className="sm:text-5xl text-2xl font-medium title-font mb-4 text-white">
        Kontaktiere mich!
      </h1>
      <p className="lg:w-2/3 mx-auto text-gray-600 leading-relaxed text-base">
        Ob du Fragen zu meinen Leistungen, Preisen oder meiner Auftragslage hast, ich freue mich über dein Interesse! Vielleicht möchtest du mir auch nur Feedback zu meiner Arbeit geben.
      </p>
    </div>
    <div className="lg:w-1/2 md:w-2/3 mx-auto">
      <div className="flex flex-wrap -m-2">
        <div className="p-2 w-1/2">
          <div className="relative">
            <label for="name" className="leading-7 text-sm text-gray-600">
              Name
            </label>
            <input
              name="user_name"
              type="text"
              id="name"
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
        </div>
        <div className="p-2 w-1/2">
          <div className="relative">
            <label
              for="email"
              className="leading-7 text-sm text-gray-600 bg-opacity-50"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="user_email" 
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-gray-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
        </div>
        <div className="p-2 w-full">
          <div className="relative">
            <label
              for="message"
              className="leading-7 text-sm text-gray-600"
            >
              Nachricht
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-gray-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            ></textarea>
          </div>
        </div>
        <div className="p-2 w-full">
          <button 
            type="submit" value="Send"
            className="flex mx-auto text-white bg-gray-500 border-0 py-2 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg">
            Senden
          </button>
        </div>
      </div>
    </div>
  </div>
  </form>
</section>

*/}