import React, { Component } from "react";

class JobOffers extends Component {

  render() {
    return (    
        <section class="">
          <div class="h-screen bg-cover bg-center flex justify-items-center items-center">
            <div class="px-10 lg:px-32 xl:px-40">
              <h1 class="text-6xl font-semibold font-sans mb-6">
                <span class="text-white">Ich suche dich!</span>
              </h1>
              <p class="text-gray-600 text-lg max-w-md">
                Du packst gerne an und willst am Ende vom Tag sehen, was du geschafft hast?
                Du hast bereits Erfahrungen im Garten- und Landschaftsbau? Super!
                Melde dich bei uns und wir lernen uns kennen.
              </p>
            </div>
          </div>
        </section>
    
    );
  }
}

export default JobOffers;