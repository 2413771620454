import React, { Component } from "react";

class References extends Component {

  render() {
    return (    
        
    <section class="">
        <div class="h-screen bg-cover bg-center flex justify-items-center items-center">
        <div class="px-10 lg:px-32 xl:px-40">
            <h1 class="text-2xl font-semibold font-sans mb-6">
            <span class="text-white">
                "Mirko und sein Team haben so eine schöne Terrasse bei uns gebaut."
            </span>
            </h1>
            <p class="text-gray-500 text-lg max-w-md">
            F. v. S. aus Oldenburg
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 class="text-2xl font-semibold font-sans mb-6">
            <span class="text-white">
              "Danke für die Umsetzung unserer Ideen. Jetzt haben wir einen Garten nach unseren
              Wünschen."
            </span>
            </h1>
            <p class="text-gray-500 text-lg max-w-md">
            Familie Keils aus Oldenburg
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 class="text-2xl font-semibold font-sans mb-6">
            <span class="text-white">
              "Wie ich es mir vorgestellt habe. Gute Beratung und Professionelle Umsetzung. Gutes Gefühl
              ab der ersten Sekunde."
            </span>
            </h1>
            <p class="text-gray-500 text-lg max-w-md">
            Günter K.
            </p>
        </div>
        </div>
    </section>

    );
  }
}

export default References;