import React, { Component } from "react";

class Space extends Component {
  render() {
    return ( 
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>    
    )}
}

export default Space 